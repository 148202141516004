var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "paperclip" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Attività")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "tableFixHead" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Titolo")]),
              _vm._v(" "),
              _c("th", [_vm._v("Descrizione")]),
              _vm._v(" "),
              _c("th", [_vm._v("Pubblico")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.items, function (todo, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(todo.title))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(todo.description))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(todo.is_public.value))]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }